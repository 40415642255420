span {
	color: rgb(52, 116, 253);
	width: 100%;
}

blockquote {
	margin: 0;
	border-left: 4px solid #ccc;
	padding: 2px 2px 2px 16px;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.6);
	background-color: rgba(0, 0, 0, 0.02);
}

#disqus_thread {
	width: 100% !important;
	min-width: 100% !important;
	border: none !important;
	overflow: hidden !important;
}

.logo {
	font-size: 1.5rem;
}
* {
	-webkit-tap-highlight-color: transparent;
}
*:focus {
	box-shadow: none !important;
}
.iconColor {
	stroke: rgb(52, 116, 253) !important;
	margin-right: 5px;
}

.whiteIconColor {
	filter: invert(99%) sepia(55%) saturate(2%) hue-rotate(241deg)
		brightness(112%) contrast(100%);
}

.mutedIconColor {
	filter: invert(50%) sepia(5%) saturate(722%) hue-rotate(167deg)
		brightness(90%) contrast(91%);
}

.leftMargin {
	margin-right: 5px;
}

.bodyStyle span {
	color: black !important;
}

.bodyStyle a span {
	color: rgb(52, 116, 253) !important;
}

.bodyStyle span a {
	color: rgb(52, 116, 253) !important;
}

/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(52, 116, 253);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
